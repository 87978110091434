import { CarouselProvider, Slider, Slide, Image } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import React from "react";

import Comment from "Comment";

import AntenaFmCircular from "assets/nossos-produtos/antena-fm-circular.webp";
import AntenaInterna from "assets/nossos-produtos/antena-interna.webp";
import AntenaLogBlack from "assets/nossos-produtos/antena-log-black.webp";
import SuporteArticulado1045 from "assets/nossos-produtos/suporte-articulado-1045.webp";
import SuporteArticulado1055 from "assets/nossos-produtos/suporte-articulado-1055.webp";
import SuporteDeTeto4500 from "assets/nossos-produtos/suporte-de-teto-4500.webp";
import SuporteDeTeto4520 from "assets/nossos-produtos/suporte-de-teto-4520.webp";
import SuporteDeTeto4530 from "assets/nossos-produtos/suporte-de-teto-4530.webp";
import SuporteInclinado1015 from "assets/nossos-produtos/suporte-inclinado-1015.webp";
import SuporteUnivTechnosat1470Trad from "assets/nossos-produtos/suporte-univ-technosat-1470-trad.webp";
import SuporteUnivTechnosat1475Slim from "assets/nossos-produtos/suporte-univ-technosat-1475-slim.webp";
import SuporteUnivTechnosat1480 from "assets/nossos-produtos/suporte-univ-technosat-1480.webp";
import SuporteUnivTechnosat1485 from "assets/nossos-produtos/suporte-univ-technosat-1485.webp";
import SuporteUnivTelesystem1490 from "assets/nossos-produtos/suporte-univ-telesystem-1490.webp";

import AntenaInternaBg from "assets/slides/antena-interna-bg.webp";
import AntenaInternaConteudo from "assets/slides/antena-interna-conteudo.webp";
import AntenaLogBg from "assets/slides/antena-log-bg.webp";
import AntenaLogConteudo from "assets/slides/antena-log-conteudo.webp";

function App(): JSX.Element {
  return (
    <>
      <CarouselProvider
        infinite={true}
        interval={5000}
        isIntrinsicHeight={false}
        isPlaying={true}
        naturalSlideHeight={2}
        naturalSlideWidth={5}
        totalSlides={2}
      >
        <Slider>
          <Slide index={0}>
            <Image
              hasMasterSpinner={true}
              isBgImage={true}
              src={AntenaLogBg}
              style={{ backgroundSize: "contain" }}
              tag="div"
            >
              <Comment text="This work is a derivative of https://unsplash.com/photos/EOQhsfFBhRk by Glenn Carstens-Peters" />
              <img alt="Antena Log" src={AntenaLogConteudo} />
            </Image>
          </Slide>
          <Slide index={1}>
            <Image
              hasMasterSpinner={true}
              isBgImage={true}
              src={AntenaInternaBg}
              style={{ backgroundSize: "contain" }}
              tag="div"
            >
              <Comment text="This work is a derivative of https://unsplash.com/photos/cpEO3HoKdKM by Kari Shea" />
              <img alt="Antena Interna" src={AntenaInternaConteudo} />
            </Image>
          </Slide>
        </Slider>
      </CarouselProvider>

      <div className="hero is-medium">
        <div className="hero-body">
          <div className="container">
            <div className="has-text-centered">
              <h1 className="py-5 title is-1">Nossos produtos</h1>
              <h5 className="is-5 my-6 pb-6 subtitle">
                Na área de produtos você encontrará uma vasta gama de itens que
                contém, por exemplo, antenas, componentes para fabricação e até
                mesmo acessórios para áudio e vídeos. A seguir, veja alguns dos
                nossos mais destacados produtos.
              </h5>
            </div>

            <div className="columns is-mobile is-multiline">
              <div className="column is-full-mobile is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd">
                <div className="img-icon">
                  <img alt="Produto" src={AntenaLogBlack} />
                </div>
                <h4 className="has-text-centered">
                  <small>ANTENAS LOG BLACK</small>
                </h4>
              </div>

              <div className="column is-full-mobile is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd">
                <div className="img-icon">
                  <img alt="Produto" src={AntenaInterna} />
                </div>
                <h4 className="has-text-centered">
                  <small>ANTENA INTERNA</small>
                </h4>
              </div>

              <div className="column is-full-mobile is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd">
                <div className="img-icon">
                  <img alt="Produto" src={AntenaFmCircular} />
                </div>
                <h4 className="has-text-centered">
                  <small>ANTENA FM CIRCULAR</small>
                </h4>
              </div>

              <div className="column is-full-mobile is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd">
                <div className="img-icon">
                  <img alt="Produto" src={SuporteUnivTechnosat1470Trad} />
                </div>
                <h4 className="has-text-centered">
                  <small>SUPORTE UNIV. 1470</small>
                </h4>
              </div>

              <div className="column is-full-mobile is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd">
                <div className="img-icon">
                  <img alt="Produto" src={SuporteUnivTechnosat1475Slim} />
                </div>
                <h4 className="has-text-centered">
                  <small>SUPORTE UNIV. 1475</small>
                </h4>
              </div>

              <div className="column is-full-mobile is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd">
                <div className="img-icon">
                  <img alt="Produto" src={SuporteUnivTechnosat1480} />
                </div>
                <h4 className="has-text-centered">
                  <small>SUPORTE UNIV. 1480</small>
                </h4>
              </div>

              <div className="column is-full-mobile is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd">
                <div className="img-icon">
                  <img alt="Produto" src={SuporteUnivTechnosat1485} />
                </div>
                <h4 className="has-text-centered">
                  <small>SUPORTE UNIV. 1485</small>
                </h4>
              </div>

              <div className="column is-full-mobile is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd">
                <div className="img-icon">
                  <img alt="Produto" src={SuporteUnivTelesystem1490} />
                </div>
                <h4 className="has-text-centered">
                  <small>SUPORTE UNIV. 1490</small>
                </h4>
              </div>

              <div className="column is-full-mobile is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd">
                <div className="img-icon">
                  <img alt="Produto" src={SuporteInclinado1015} />
                </div>
                <h4 className="has-text-centered">
                  <small>SUPORTE INCLINADO 1015</small>
                </h4>
              </div>

              <div className="column is-full-mobile is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd">
                <div className="img-icon">
                  <img alt="Produto" src={SuporteArticulado1045} />
                </div>
                <h4 className="has-text-centered">
                  <small>SUPORTE ARTICULADO 1045</small>
                </h4>
              </div>

              <div className="column is-full-mobile is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd">
                <div className="img-icon">
                  <img alt="Produto" src={SuporteArticulado1055} />
                </div>
                <h4 className="has-text-centered">
                  <small>SUPORTE ARTICULADO 1055</small>
                </h4>
              </div>

              <div className="column is-full-mobile is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd">
                <div className="img-icon">
                  <img alt="Produto" src={SuporteArticulado1055} />
                </div>
                <h4 className="has-text-centered">
                  <small>SUPORTE ARTICULADO 1065</small>
                </h4>
              </div>

              <div className="column is-full-mobile is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd">
                <div className="img-icon">
                  <img alt="Produto" src={SuporteDeTeto4500} />
                </div>
                <h4 className="has-text-centered">
                  <small>SUPORTE DE TETO 4500</small>
                </h4>
              </div>

              <div className="column is-full-mobile is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd">
                <div className="img-icon">
                  <img alt="Produto" src={SuporteDeTeto4520} />
                </div>
                <h4 className="has-text-centered">
                  <small>SUPORTE DE TETO 4520</small>
                </h4>
              </div>

              <div className="column is-full-mobile is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd">
                <div className="img-icon">
                  <img alt="Produto" src={SuporteDeTeto4530} />
                </div>
                <h4 className="has-text-centered">
                  <small>SUPORTE DE TETO 4530</small>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;

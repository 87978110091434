import { Switch, Route } from "react-router-dom";
import Localizacao from "routes/Localizacao";
import logo from "assets/logo.png";
import QuemSomos from "routes/QuemSomos";
import React, { Component } from "react";
import Home from "routes/Home";
import WhatsappIcon from "assets/whatsapp.png";

class App extends Component {
  render(): JSX.Element {
    return (
      <>
        <header>
          <nav
            className="is-transparent has-text-weight-bold navbar"
            role="navigation"
            aria-label="main navigation"
          >
            <div className="container">
              <div className="navbar-brand">
                <a className="navbar-item" href="/">
                  <img alt="Tecnosat" height="50" src={logo} width="200" />
                </a>

                <button
                  aria-label="menu"
                  aria-expanded="false"
                  className="navbar-burger burger"
                  data-target="navMenu"
                >
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                </button>
              </div>

              <div className="navbar-menu" id="navMenu">
                <div className="navbar-end">
                  <a className="navbar-item" href="/">
                    INÍCIO
                  </a>
                  <a
                    className="navbar-item"
                    href="mailto:comercial@technosat.ind.br"
                  >
                    CONTATO
                  </a>
                  <a className="navbar-item" href="/localizacao">
                    LOCALIZAÇÃO
                  </a>

                  <a
                    className="navbar-item"
                    href="/produtos.pdf"
                    target="_blank"
                  >
                    PRODUTOS
                  </a>

                  <a className="navbar-item" href="/quem-somos">
                    QUEM SOMOS
                  </a>

                  <a className="navbar-item" href="https://wa.me/5511944708787" title="WhatsApp">
                    {/* https://fontawesome.com/license */}
                    <img alt="WhatsApp" src={WhatsappIcon} width="30" />
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </header>

        <main>
          <Switch>
            <Route path="/localizacao" component={Localizacao} />
            <Route path="/quem-somos" component={QuemSomos} />
            <Route component={Home} />
          </Switch>
        </main>

        <footer className="footer">
          <div className="content has-text-centered">
            <strong>
              Av. das Ameixeiras, 504 - Taboão - Diadema - SP,
              09940-400 | +55 (11) 9 4470-8787
            </strong>
          </div>
        </footer>
      </>
    );
  }

  componentDidMount(): void {
    const navbarBurgers = Array.prototype.slice.call(
      document.querySelectorAll(".navbar-burger"),
      0
    );
    if (navbarBurgers.length > 0) {
      navbarBurgers.forEach((e) => {
        e.addEventListener("click", () => {
          const target = document.getElementById(e.dataset.target);
          e.classList.toggle("is-active");
          target?.classList.toggle("is-active");
        });
      });
    }
  }
}

export default App;

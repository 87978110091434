import React from "react";

function App(): JSX.Element {
  return (
    <div className="hero is-fullheight-with-navbar">
      <div className="hero-body">
        <div className="container">
          <figure className="image is-16by9">
          <iframe
              aria-hidden="false"
              className="has-ratio"
              frameBorder="0"
              height="360"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d228.39162489825037!2d-46.6038928!3d-23.6664437!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce44bca68eeee1%3A0xa1d98d2a673c5f34!2sAv.%20das%20Ameixeiras%2C%20504%20-%20Tabo%C3%A3o%2C%20Diadema%20-%20SP%2C%2009940-400!5e0!3m2!1sen!2sbr!4v1712781117371!5m2!1sen!2sbr"
              tabIndex={0}
              title="Technosat"
              width="640"
          ></iframe>
          </figure>
        </div>
      </div>
    </div>
  );
}

export default App;

import React from "react";

import QuemSomosInfo from "assets/quem-somos-info.webp";
import Comment from "Comment";
import QuemSomosSobre from "assets/quem-somos-sobre.webp";

function App(): JSX.Element {
  return (
    <div className="hero is-fullheight-with-navbar is-mobile">
      <div className="hero-body">
        <div className="container">
          <div className="columns">
            <div className="column is-narrow">
              <figure className="image">
                <Comment text="Photo by Sanni Sahil on https://unsplash.com/photos/rgRAEobMmUU" />
                <img
                  alt="Sobre nós"
                  height="400"
                  src={QuemSomosSobre}
                  width="225"
                />
              </figure>
            </div>

            <div className="column">
              <div className="content">
                <h3 className="is-3 is-family-secondary has-text-info-dark subtitle">
                  Sobre nós
                </h3>
                A Technosat fabricante de antenas e suportes para televisores
                residenciais, com mais de 20 anos de experiência, tem se
                destacado por sua visão estratégica, sempre inovando e
                investindo em tecnologia e capital humano. Atuando inclusive
                como fornecedora de matéria prima para outras indústrias do
                mesmo seguimento.
              </div>
            </div>
          </div>

          <div className="columns mt-6">
            <div className="column">
              <div className="content">
                <h3 className="is-3 is-family-secondary has-text-info-dark subtitle">
                  Visão
                </h3>
                Buscar constantemente o aperfeiçoamento e a melhora de seus
                produtos, para poder oferecer componentes diferenciados e de
                qualidade.
                <h3 className="is-3 is-family-secondary has-text-info-dark subtitle">
                  Missão
                </h3>
                Produzir produtos que preservem o meio ambiente gerando riqueza
                para o País sem prejudicar a qualidade de vida, ou seja, ter uma
                posição destacada no mercado, com responsabilidade ambiental.
                <h3 className="is-3 is-family-secondary has-text-info-dark subtitle">
                  Objetivos
                </h3>
                Crescer com ética e diferenciar-se dos demais ao oferecer
                produtos inteligentes, inovadores, de qualidade e ecologicamente
                corretos, visando o conforto e a segurança do consumidor.
                Trazendo desta forma o retorno necessário e a conseqüente
                fidelização dos envolvidos.
              </div>
            </div>

            <div
              className="column is-narrow"
              style={{ alignItems: "center", display: "flex" }}
            >
              <figure className="image">
                <Comment text="Photo by Timon Studler on https://unsplash.com/s/photos/people?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText" />
                <img
                  alt="Valores"
                  height="380"
                  src={QuemSomosInfo}
                  width="340"
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

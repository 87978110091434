import React, { useEffect, useRef } from "react";

const ReactComment = (props: { text: string }): JSX.Element => {
  const el = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (el && el.current) {
      el.current.outerHTML = `<!-- ${props.text} -->`;
    }
  }, [props.text]);
  return <div ref={el} />;
};

export default ReactComment;
